<template>
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
                <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
                    <el-form-item label="姓名:">
                        <el-input v-model="selectForm.name" placeholder="姓名"></el-input>
                    </el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <!--列表-->
            <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
                <el-table-column prop="UserName" label="姓名"> </el-table-column>
                <el-table-column label="公司" prop="Factory"></el-table-column>
                <el-table-column label="部门" prop="Dpt"></el-table-column>
                <el-table-column label="岗位" prop="Post"></el-table-column>
                <!-- <el-table-column label="发布部门" prop="IssuedDepartment"></el-table-column> -->
                <el-table-column label="执行日期" prop="ExecuteDate" :formatter="formatStartTime"></el-table-column>
                <el-table-column label="执行方式" prop="ExecuteType"></el-table-column>
                <el-table-column label="类型" prop="Type"></el-table-column>
                <el-table-column label="金额" prop="Salary"></el-table-column>
                <el-table-column label="状态" prop="State"></el-table-column>
                <el-table-column label="具体描述" show-overflow-tooltip prop="Explain"></el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper ,total" :page-count="total" :total="totaldata"></el-pagination>
        <el-dialog :title="operation ? '添加' : '编辑'" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
            <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" :disabled="true" v-model="SakaryForm.UserName">
                                <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="公司:" prop="Factory" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Factory" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="部门:" prop="Dpt" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Dpt" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="岗位:" prop="Post" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" :disabled="true" v-model="SakaryForm.Post"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发布公司:" prop="IssuedDepartment" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.IssuedDepartment"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="评定日期:" prop="ReleaseDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-date-picker type="date" v-model="SakaryForm.ReleaseDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="执行日期:" prop="ExecuteDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-date-picker type="date" v-model="SakaryForm.ExecuteDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="执行方式:" prop="ExecuteType" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="SakaryForm.ExecuteType" placeholder="请选择" style="width: 100%">
                                <el-option label="无" value="无"></el-option>
                                <el-option label="现金" value="现金"></el-option>
                                <el-option label="工资体现" value="工资体现"></el-option>
                                <el-option label="内购卷" value="内购卷"></el-option>
                                <el-option label="资金池" value="资金池"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型:" prop="Type" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="SakaryForm.Type" placeholder="请选择" style="width: 100%">
                                <el-option label="奖励" value="奖励"></el-option>
                                <el-option label="惩罚" value="惩罚"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="金额:" prop="Salary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Salary" @blur="inputMoney('Salary')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="具体描述">
                            <el-input type="textarea" v-model="SakaryForm.Explain"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="SakaryForm.Type == '奖励'">
                    <el-col :span="12">
                        <el-form-item label="奖励类型:" prop="Type" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="SakaryForm.RewardType" placeholder="请选择" style="width: 100%">
                                <el-option label="其他" value="其他"></el-option>
                                <el-option label="标兵" value="标兵"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="SakaryForm.RewardType == '标兵'">
                        <el-form-item label="标兵名称:">
                            <el-input type="text" v-model="SakaryForm.ModelName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="SakaryForm.RewardType == '标兵' && SakaryForm.Type == '奖励'">
                    <el-col :span="24">
                        <el-form-item label="同步党建:" prop="ModeSync">
                            <el-switch v-model="SakaryForm.ModeSync" active-text="同步" inactive-text="不同步"> </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="SakaryForm.RewardType == '标兵' && SakaryForm.Type == '奖励'">
                    <el-col :span="24">
                        <el-form-item label="标兵描述">
                            <el-input type="textarea" v-model="SakaryForm.ModeRemark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="SakaryForm.RewardType == '标兵' && SakaryForm.Type == '奖励'">
                    <el-col :span="18">
                        <el-form-item label="标兵图片">
                            <a target="_blank" :href="SakaryForm.ModeUrl">{{SakaryForm.ModeUrlName}}</a>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-button icon="el-icon-search" circle @click="onShowDlg"></el-button>
                    </el-col>
                </el-row>
                <upload-files :files="SakaryForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
                <el-row style="margin-bottom: 20px">
                    <div class="tableTitle">
                        <span class="midText">审核信息</span>
                    </div>
                </el-row>
                <el-table :data="SakaryForm.Approvals" highlight-current-row height="100%" class="new_table">
                    <el-table-column prop="ApprovalName" label="类型"> </el-table-column>
                    <el-table-column label="审核日期" prop="OptDate" :formatter="formatStartTime"></el-table-column>
                    <el-table-column label="审核状态" prop="State"></el-table-column>
                    <el-table-column label="备注" prop="Remark"></el-table-column>
                </el-table>
                <el-row v-if="operation1">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
            <UserChoose :data="choosedusers" :all="true" :single="false" @callback="usercallFunction"></UserChoose>
        </el-dialog>
        <el-dialog title="图片" :visible.sync="cropperDlgVisible" v-model="cropperDlgVisible" width="1000px" center append-to-body>
            <cropperModal action="https://api.gradgroup.cn/ftp/ftp/upload" @callback="cropperCallFunction"></cropperModal>
        </el-dialog>
        <multiRewardModal ref="multiRewardModal" @callback="getData"></multiRewardModal>
        <RewardPunishment ref="RewardPunishment" @callback="getData"></RewardPunishment>
    </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import {
  GetRewardsList,
  AddRewards,
  UpdateRewards,
  GetUserPostDptOrg,
  GetRewardsDetail,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/newToolbar";
    import cropperModal from '../componentModals/cropperModal'
    import multiRewardModal from "../componentModals/multiRewardModal"    // 批量奖惩
    import RewardPunishment from "../componentModals/RewardPunishment"    // 奖惩
export default {
        components: { Toolbar, UserChoose, UserChoosejob, UploadFiles, cropperModal, multiRewardModal,RewardPunishment },
  data() {
    return {
      loading: true,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      key: 0,
      choosedusers: [],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
        addUserVisible: false,
        cropperDlgVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      SakaryForm: {
        UserId: "",
        UserName: "",
        Post: "",
        Dpt: "",
        Type: "",
        IssuedDepartment: "",
        ExecuteType: "",
        Salary: "",
        Explain: "",
        ExecuteDate: "",
        Factory: "",
        FileArry: [],
      },
      selectForm: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      GetUserPostDptOrg({ userId: newdata[0].Id }).then((res) => {
          if (res.data.success) {
          this.SakaryForm.Factory = res.data.response.OrganizationName;
          this.SakaryForm.Dpt = res.data.response.DepartmentName;
          this.SakaryForm.DptId = res.data.response.DepartmentId;
          this.SakaryForm.Post = res.data.response.PostName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },
      cropperCallFunction(newdata) {
          console.log(newdata);
          console.log("cropperCallFunction", newdata);
          console.log("newdata.response.HttpHost", newdata.newdata.data.httpHost);
          this.SakaryForm.ModeUrl = newdata.newdata.data.httpHost + newdata.newdata.data.filePath;
          this.SakaryForm.ModeUrlName = "标兵图片" + newdata.newdata.data.fileExtension;
          console.log(this.SakaryForm)
          this.cropperDlgVisible = false;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },

    chooseUser() {
      this.addUserVisible = true;
      },
      onShowDlg() {
          this.cropperDlgVisible = true;
      },
      
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.name,
      };

      GetRewardsList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {//编辑
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      // GetRewardsDetail({ id: row.Id }).then((res) => {
      //   this.SakaryForm = res.data.response;
      //   this.key += 1;
      //   this.SakaryVisible = true;
      // });
      // this.operation = false;
      // this.operation1 = true;
      this.$refs.RewardPunishment.operation = false; // 添加
      this.$refs.RewardPunishment.optType = true;   // 编辑
      this.$refs.RewardPunishment.operation1 = true;   // 附件编辑
      this.$refs.RewardPunishment.optDisabled = false;   // 禁用
      this.$refs.RewardPunishment.onShowDlg(row.Id);
    },
    handleShow() {//查看
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      // GetRewardsDetail({ id: row.Id }).then((res) => {
        // this.SakaryForm = res.data.response;
        // this.key += 1;
        // this.SakaryVisible = true;
      // });
      // this.operation = false;
      // this.operation1 = false;
      this.$refs.RewardPunishment.operation = false; // 添加
      this.$refs.RewardPunishment.optType = false;   // 编辑
      this.$refs.RewardPunishment.operation1 = false;   // 附件编辑
      this.$refs.RewardPunishment.optDisabled = true;   // 禁用
      this.$refs.RewardPunishment.onShowDlg(row.Id);
    },
      handleAdd() {//添加
          // (this.SakaryForm = {
          //     UserId: "",
          //     UserName: "",
          //     Post: "",
          //     Dpt: "",
          //     Type: "",
          //     IssuedDepartment: "",
          //     ExecuteType: "",
          //     Salary: "",
          //     Explain: "",
          //     ExecuteDate: "",
          //     Factory: "",
          //     FileArry: [],
          // }),
          //     (this.key += 1);
          // (this.operation = true), (this.SakaryVisible = true);
          // this.operation1 = true;

          this.$refs.RewardPunishment.operation = true; // 添加
          this.$refs.RewardPunishment.optType = true;   // 编辑
          this.$refs.RewardPunishment.operation1 = true;   // 附件编辑
          this.$refs.RewardPunishment.optDisabled = false;   // 禁用
          this.$refs.RewardPunishment.onShowDlg();
      },
      handleMultiAdd() {
          this.$refs.multiRewardModal.operation = true; // 添加
          this.$refs.multiRewardModal.optType = true;   // 编辑
          this.$refs.multiRewardModal.onShowDlg();
      },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.SakaryForm.ModeUrlName = this.SakaryForm.Factory + '-' + this.SakaryForm.ModelName + '-' + this.SakaryForm.UserName;
            let para = Object.assign({}, this.SakaryForm);
              if (this.operation && this.operation1) {
              //新增
              AddRewards(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
              } else if (!this.operation && this.operation1){
              //修改
              UpdateRewards(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      this.SakaryForm.FileArry = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.tableTitle {
  position: relative;
  margin: 0 auto;

  height: 1px;
  background-color: #d4d4d4;
  text-align: center;
  font-size: 16px;
  color: rgba(101, 101, 101, 1);
}
</style>

